import React from 'react';
import {TenantUtilities} from "../../Utilities/TenantUtilities";

class HeaderArea extends React.Component {

    constructor(props) {
        super(props);

        this.profileSettings= props.profileSettings;
        this.dropdownChild   = React.createRef();
        //this.logIn = this.logIn.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        document.body.addEventListener('click', this.closeDropdown.bind(this));
        this.openProfileSettings  = this.openProfileSettings.bind(this);

    }

    openProfileSettings() {
      this.profileSettings.current.style.left = 0;
    }

    openDropdown() {
        this.dropdownChild.current.classList.add("show")
    }

    closeDropdown() {
        this.dropdownChild.current.classList.remove("show")
    }

    //logIn() {
        //var provider = new window.firebase.auth.GoogleAuthProvider();
        // [END createprovider]
        // [START addscopes]
        //provider.addScope('https://www.googleapis.com/auth/plus.login');
        // [END addscopes]
        // [START signin]
        //window.firebase.auth().signInWithRedirect(provider);
    //}


    renderLogInButton() {
        if (this.props.currentAgent == null) {
            return <button className="button is-primary" onClick={this.props.logIn}>Iniciar sesión</button>
        } else {
            return <button className="button is-primary" onClick={this.props.logOut}>Cerrar sesión</button>
        }
    }

    renderTenants() {
        if (this.props.currentAgent) {
            const tenants = TenantUtilities.GET_TENANTS_WITH_NAMES();
            return (
                <div className="navbar-item has-dropdown is-hoverable">
                    {/* eslint-disable-next-line */}
                    <a className="navbar-link">
                        Organización
                    </a>
                    <div className="navbar-dropdown">
                        {Object.entries(tenants).map((([key, value]) => {
                            if(key === '') {
                                return;
                            }
                            return <a className="navbar-item" href={'/' + key} key={key}>
                                {value}
                            </a>
                        }))}
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
              <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                    <a className="navbar-item" href="/">Inicio</a>

                    {this.renderTenants()}
                </div>

                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">
                        <div onClick={this.openDropdown}>
                            <div ref={this.dropdownChild}>
                                {this.renderLogInButton()}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
        )
    }
}

export default HeaderArea;
