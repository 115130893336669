export class GroupUtilities {
    static GROUP_VENTAS_DIGITALES = 'ventas-digitales';
    static GROUP_LIDER_DE_EXPERIENCIA = 'lxp';
    static GROUP_LIDER_DE_EXPERIENCIA_CENTRAL = 'lxp-central';
    static GROUP_HEY_AUTOMOTRIZ = 'hey-automotriz';
    static GROUP_COBRANZA = 'cobranza';
    static GROUP_INMUEBLES = 'inmuebles';
    static GROUP_TESORERIA = 'tesoreria';
    static GROUP_AUTO_PF_NORTE = 'auto-pf-norte';
    static GROUP_AUTO_PF_CENTRO = 'auto-pf-centro';
    static GROUP_AUTO_PF_OCCIDENTE = 'auto-pf-occidente';
    static GROUP_AUTO_PF_NOROESTE = 'auto-pf-noroeste';
    static GROUP_ASISTENTE_OB = 'asisOB';
    static GROUP_CREDITO_AUTO = 'credito-auto';
    static GROUP_PREFERENTE = 'preferente';

    /**
     *
     * @returns {(string)[]}
     * @constructor
     */
    static GET_GROUPS = () => {
        return [
            GroupUtilities.GROUP_VENTAS_DIGITALES,
            GroupUtilities.GROUP_LIDER_DE_EXPERIENCIA,
            GroupUtilities.GROUP_LIDER_DE_EXPERIENCIA_CENTRAL,
            GroupUtilities.GROUP_HEY_AUTOMOTRIZ,
            GroupUtilities.GROUP_COBRANZA,
            GroupUtilities.GROUP_INMUEBLES,
            GroupUtilities.GROUP_TESORERIA,
            GroupUtilities.GROUP_AUTO_PF_NORTE,
            GroupUtilities.GROUP_AUTO_PF_CENTRO,
            GroupUtilities.GROUP_AUTO_PF_OCCIDENTE,
            GroupUtilities.GROUP_AUTO_PF_NOROESTE,
            GroupUtilities.GROUP_ASISTENTE_OB,
            GroupUtilities.GROUP_CREDITO_AUTO,
            GroupUtilities.GROUP_PREFERENTE,

        ];
    };

    /**
     *
     * @returns {{}}
     * @constructor
     */
    static GET_GROUPS_WITH_NAMES = () => {
        let groups = {};
        groups[GroupUtilities.GROUP_VENTAS_DIGITALES] = 'Ventas digitales';
        groups[GroupUtilities.GROUP_LIDER_DE_EXPERIENCIA] = 'Líder de experiencia';
        groups[GroupUtilities.GROUP_LIDER_DE_EXPERIENCIA_CENTRAL] = 'LXP Central';
        groups[GroupUtilities.GROUP_HEY_AUTOMOTRIZ] = 'Hey Automotriz';
        groups[GroupUtilities.GROUP_COBRANZA] = 'Cobranza';
        groups[GroupUtilities.GROUP_INMUEBLES] = 'Inmuebles';
        groups[GroupUtilities.GROUP_TESORERIA] = 'Tesoreria';
        groups[GroupUtilities.GROUP_AUTO_PF_NORTE] = 'Auto PF Norte';
        groups[GroupUtilities.GROUP_AUTO_PF_CENTRO] = 'Auto PF Centro';
        groups[GroupUtilities.GROUP_AUTO_PF_OCCIDENTE] = 'Auto PF Occidente';
        groups[GroupUtilities.GROUP_AUTO_PF_NOROESTE] = 'Auto PF Noroeste';
        groups[GroupUtilities.GROUP_ASISTENTE_OB] = 'Asistente Onboarding';
        groups[GroupUtilities.GROUP_CREDITO_AUTO] = 'Crédito Automotriz';
        groups[GroupUtilities.GROUP_PREFERENTE] = 'Preferente';
        
        return groups;
    };
}