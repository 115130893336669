import React from 'react';
import { forwardRef } from 'react';

import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {GroupUtilities} from "../../Utilities/GroupUtilities";
import {RoleUtilities} from "../../Utilities/RoleUtilities";

export default function UserList(props) {

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const data=props.agentList

  const rolesWithNames = RoleUtilities.GET_ROLES_WITH_NAMES();

  const columns=[
    { title: 'Correo', field: 'email', editable: 'onAdd' },
    { title: 'Rol', field: 'role', lookup: RoleUtilities.GET_ROLES_WITH_NAMES(), type: 'numeric' },
    { title: 'Número de empleado', field: 'employeeId'},
    { title: 'Grupo', field: 'group', lookup: GroupUtilities.GET_GROUPS_WITH_NAMES(), type: 'numeric' },
    { title: 'ID de promotor', field: 'promoterId'},
  ]

  const exportData = () => {
    const csvRows = [];

    const headerRow = columns.map(column => column.title);
    csvRows.push(headerRow);

    data.forEach(item => {
      item.role = rolesWithNames[item.role];
      const rowData = columns.map(column => item[column.field]);
      csvRows.push(rowData);
    });

    const csvContent = csvRows.map(row => row.join(',')).join('\n');

    // Generar un archivo CSV y descargarlo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const docName = window.location.pathname.slice(1) != ''? window.location.pathname.slice(1): "usuarios";
    link.setAttribute('download', docName+'.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <MaterialTable
      options={{
        pageSize: 10,
        exportButton: true,
        exportCsv: () => exportData(), // Llama a la función exportData al hacer clic en el botón de exportar

      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'registros'
        },
        header: {
          actions: 'Acciones'
        },
        body: {
          emptyDataSourceMessage: 'Sin registros.',
          addTooltip: 'Añadir',
          deleteTooltip: 'Eliminar',
          editTooltip: 'Editar',
          editRow: {
            deleteText: '¿Estás seguro de eliminar este registro?',
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Guardar'
          }
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar'
        }
      }}
      title="Usuarios"
      columns={[
        { title: 'Correo', field: 'email', editable: 'onAdd' },
        { title: 'Rol', field: 'role', lookup: RoleUtilities.GET_ROLES_WITH_NAMES(), type: 'numeric' },
        { title: 'Número de empleado', field: 'employeeId'},
        { title: 'Grupo', field: 'group', lookup: GroupUtilities.GET_GROUPS_WITH_NAMES(), type: 'numeric' },
        { title: 'ID de promotor', field: 'promoterId'},
      ]}
      data={props.agentList}
      icons={tableIcons}
      editable={{
        isEditable: rowData => false,
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              if(!newData.email || !newData.employeeId || !newData.group) {
                alert("Se requieren todos los campos para completar el registro.")
                return
              }
              newData.email = newData.email.replace(/\./g,' ');
              props.writeUserData(newData);
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              if(!newData.email || !newData.employeeId || !newData.group) {
                alert("Se requieren todos los campos para completar el registro.")
                return
              }
              if (oldData) {
                newData.email = oldData.email;
                newData.email = newData.email.replace(/\./g,' ');
                props.updateUserData(newData);
              }
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              oldData.email = oldData.email.replace(/\./g,' ');
              props.deleteUserData(oldData);
            }, 600);
          }),
      }}
    />
  );
}