import React from 'react';
import './App.css';

import HeaderArea from './components/header_area/header_area.js';
import UserList from './components/user_list/user_list.js';

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {TenantUtilities} from "./Utilities/TenantUtilities";

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentAgent: JSON.parse(sessionStorage.getItem("currentAgent")),
      agentList: []
    }

    this.logOut = this.logOut.bind(this);
    this.logIn = this.logIn.bind(this);
    this.setConection = this.setConection.bind(this);
    this.validateUser = this.validateUser.bind(this);
    this.writeUserData = this.writeUserData.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.deleteUserData = this.deleteUserData.bind(this);
  }

  renderUserList() {
      if (this.state.currentAgent == null) {
          return <div></div>
      } else {
          return (
              <UserList
                currentAgent={this.state.currentAgent}
                agentList={this.state.agentList}
                writeUserData={this.writeUserData}
                updateUserData={this.updateUserData}
                deleteUserData={this.deleteUserData}
              />
          )
      }
  }

  render() {

    return (
      <Router>
        <Switch>
          <Route path="/">
            <div>
              <HeaderArea
                currentAgent={this.state.currentAgent}
                logOut={this.logOut}
                logIn={this.logIn}
              />
              {this.renderUserList()}
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }

  writeUserData (user) {
    window.firebase.database().ref('usuario/' + user.email).set({
      nombre: user.email,
      rol: parseInt(user.role),
      idEmpleado: user.employeeId,
      grupo: user.group,
      idPromotor: user.promoterId || "",
    });
  }

  updateUserData (user) {
    window.firebase.database().ref('usuario/' + user.email).update({
      nombre: user.email,
      rol: parseInt(user.role),
      idEmpleado: user.employeeId,
      grupo: user.group,
      idPromotor: user.promoterId || "",
    });
  }

  deleteUserData (user) {
    window.firebase.database().ref('usuario/' + user.email).remove();
  }

  logOut() {
      var db = window.firebase.database();
      var userAgentEmail=this.state.currentAgent.email.replace(/\./g, " ");
      var myConnectionsRef = db.ref('usuario/'+userAgentEmail+'/conexiones');
      myConnectionsRef.remove();
      sessionStorage.clear();
      this.setState({currentAgent:null});
      window.firebase.auth().signOut();
      window.location = "/";
  }

  validateUser(userAgent){
    let that = this;
    var db = window.firebase.database();
    var userAgentEmail=userAgent.email.replace(/\./g, " ");
    var userRef= db.ref('usuario/'+userAgentEmail);
    console.log('Buscando usuario: '+ userAgentEmail);
    //var rol=null;
    userRef.once('value', function(data){
      let document = data.val();
      //console.log('encontro');
      //console.log(document);
      //var rol= document.rol;

      //console.log('Rol:'+rol);
      if(document!=null && document.rol === 2){
        that.setState({ currentAgent: userAgent })
        sessionStorage.setItem("currentAgent", JSON.stringify(userAgent))
        that.setConection(userAgentEmail);
      }else{
        // that.showAlert('Agente no registrado');
        alert('Acceso no autorizado.');
      }
    })
  }

  setConection(userAgentEmail){
    var db = window.firebase.database();
        //var userAgentEmail=this.state.currentAgent.email.replace(/\./g, " ");
        // since I can connect from multiple devices or browser tabs, we store each connection instance separately
        // any time that connectionsRef's value is null (i.e. has no children) I am offline
        var myConnectionsRef = db.ref('usuario/'+userAgentEmail+'/conexiones');

        // stores the timestamp of my last disconnect (the last time I was seen online)
        var lastOnlineRef = db.ref('usuario/'+userAgentEmail+'/cerroSesion');

        var connectedRef = db.ref('.info/connected');
        connectedRef.on('value', function(snap) {
          if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
            var con = myConnectionsRef.push();
            // When I disconnect, remove this device
            con.onDisconnect().remove();

            // Add this device to my connections list
            // this value could contain info about the device or a timestamp too
            con.set(true);

            // When I disconnect, update the last time I was seen online
            lastOnlineRef.onDisconnect().set(window.firebase.database.ServerValue.TIMESTAMP);
          }
        });

  }

  logIn = async () => {
    var googleProvider = new window.firebase.auth.GoogleAuthProvider();
    try {
      const result = await window.firebase.auth().signInWithPopup(googleProvider);
      console.log('User signed in: ', result.user);
      this.handleRedirectResult(result);
    } catch (error) {
      console.error('Error signing in: ', error);
    }
  };


handleRedirectResult = (result) => {
    if (result.credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
    }
    if(result.user) {
      this.validateUser(result.user);
    }
  };

  componentDidMount() {
    let that = this;
    const dbname = TenantUtilities.GET_TENANT_BY_USER_SELECTION((window.location.pathname.slice(1)));
    console.log(`Connecting to ${dbname} database`)

    var firebaseConfig = {
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${dbname}-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    };

    // Initialize Firebase
    window.firebase.initializeApp(firebaseConfig);

    // window.firebase.auth().getRedirectResult().then(function(result) {
    //  if (result.credential) {
    //     This gives you a Google Access Token. You can use it to access the Google API.
    //     var token = result.credential.accessToken;
    //   }
    //   The signed-in user info.
    //   console.log("getRedirectResult")
    //   if(result.user) {
    //     that.validateUser(result.user);
    //   }

    // }).catch(function(error) {
    //   console.log(error)
    //   Handle Errors here.
    //   var errorCode = error.code;
    //   [START_EXCLUDE]
    //   if (errorCode === 'auth/account-exists-with-different-credential') {
    //     alert('You have already signed up with a different auth provider for that email.');
    //     If you are using multiple auth providers on your app you should handle linking
    //     the user's accounts here.
    //   } else {
    //     console.error(error);
    //   }
    //   [END_EXCLUDE]
    // });

    var db = window.firebase.database();

    db.ref('usuario').on('child_added', function(data){
        let document =data.val();
        //console.log(document);
        let key =data.key.replace(/\s/g, '.');
        let agente={
          email: key,
          //name:document.nombre.toLowerCase(),
          //connected:document.conexiones? true : false,
          //picture: document.photoURL,
          role: document.rol,
          employeeId: document.idEmpleado,
          group: document.grupo,
          promoterId: document.idPromotor
        };

        //if(document.rol===1  ){
          let newState = that.state.agentList.concat(agente);
          that.setState({ agentList: newState })
        //}
    });

    db.ref('usuario').on('child_changed', function(data){
        let document =data.val();
        let key = data.key.replace(/\s/g, '.');

        let newState = that.state.agentList.map(agent => {
          if(agent.email === key) {
            agent.role = document.rol
            agent.employeeId = document.idEmpleado
            agent.group = document.grupo
            agent.promoterId = document.idPromotor
          }
          return agent
        });

        that.setState({ agentList: newState })
    });


    db.ref('usuario').on('child_removed', function(data){
        let key =data.key;

        let newState = that.state.agentList.filter(agent => {
          return agent.email !== key;
        });

        that.setState({ agentList: newState })
    });
  }

}

export default App;
