export class TenantUtilities {
    static TENANT_MESSAGING = 'messaging';
    static TENANT_HEY_BANCO = 'heybanco';
    static TENANT_BANREGIO = 'banregio';
    static TENANT_HEY_RESEARCH = 'heyresearch';
    static TENANT_HEY_SHOP = 'heyshop';
    static TENANT_HEY_SOLUCIONES = 'heysoluciones';
    static TENANT_NEXO = 'nexo';
    static TENANT_INMUEBLES = 'inmuebles';
    static TENANT_PRIME = 'prime';

    /**
     *
     * @returns {(string)[]}
     * @constructor
     */
    static GET_TENANTS = () => {
        return [
            TenantUtilities.TENANT_MESSAGING,
            TenantUtilities.TENANT_HEY_BANCO,
            TenantUtilities.TENANT_BANREGIO,
            TenantUtilities.TENANT_HEY_RESEARCH,
            TenantUtilities.TENANT_HEY_SHOP,
            TenantUtilities.TENANT_HEY_SOLUCIONES,
            TenantUtilities.TENANT_NEXO,
            TenantUtilities.TENANT_INMUEBLES,
            TenantUtilities.TENANT_PRIME,

        ];
    };

    /**
     *
     * @returns {{}}
     * @constructor
     */
    static GET_TENANTS_WITH_NAMES = () => {
        let tenants = {};
        tenants[TenantUtilities.TENANT_MESSAGING] = '';
        tenants[TenantUtilities.TENANT_BANREGIO] = 'Banregio';
        tenants[TenantUtilities.TENANT_HEY_BANCO] = 'Hey Banco';
        tenants[TenantUtilities.TENANT_HEY_RESEARCH] = 'Hey Research';
        tenants[TenantUtilities.TENANT_HEY_SHOP] = 'Hey Shop';
        tenants[TenantUtilities.TENANT_HEY_SOLUCIONES] = 'Hey Soluciones';
        tenants[TenantUtilities.TENANT_NEXO] = 'Nexo';
        tenants[TenantUtilities.TENANT_INMUEBLES] = 'Inmuebles';
        tenants[TenantUtilities.TENANT_PRIME] = 'Prime';
        return tenants;
    };

    /**
     *
     * @param {(string)|null} [selection]
     * @returns {{"": string, prime: string, banregio: string, heyshop: string, heysoluciones: string, heybanco: string, heyresearch: string, nexo: string, inmuebles: string}}
     * @constructor
     */
    static GET_TENANT_BY_USER_SELECTION = (selection = null) => {
        let tenants = {
            "": TenantUtilities.TENANT_MESSAGING,
            "heybanco": TenantUtilities.TENANT_HEY_BANCO,
            "banregio": TenantUtilities.TENANT_BANREGIO,
            "heyresearch": TenantUtilities.TENANT_HEY_RESEARCH,
            "heyshop": TenantUtilities.TENANT_HEY_SHOP,
            "heysoluciones": TenantUtilities.TENANT_HEY_SOLUCIONES,
            "nexo": TenantUtilities.TENANT_NEXO,
            "inmuebles": TenantUtilities.TENANT_INMUEBLES,
            "prime": TenantUtilities.TENANT_PRIME,
        };

        if(selection != null) {
            if(!selection in tenants) {
                tenants = TenantUtilities.TENANT_MESSAGING;
            }
            return tenants[selection];
        }

        return tenants;
    };
}