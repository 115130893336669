export class RoleUtilities {
    static ROLE_AGENTE = 1;
    static ROLE_SUPERVISOR = 3;
    static ROLE_ANALISTA_SEGURIDAD_LOGICA = 2;
    static ROLE_MONITOR = 4;

    /**
     *
     * @returns {(string)[]}
     * @constructor
     */
    static GET_ROLES = () => {
        return [
            RoleUtilities.ROLE_AGENTE,
            RoleUtilities.ROLE_SUPERVISOR,
            RoleUtilities.ROLE_ANALISTA_SEGURIDAD_LOGICA,
            RoleUtilities.ROLE_MONITOR,

        ];
    };

    /**
     *
     * @returns {{}}
     * @constructor
     */
    static GET_ROLES_WITH_NAMES = () => {
        let roles = {};
        roles[RoleUtilities.ROLE_AGENTE] = 'Agente Call Center';
        roles[RoleUtilities.ROLE_SUPERVISOR] = 'Supervisor Call Center';
        roles[RoleUtilities.ROLE_ANALISTA_SEGURIDAD_LOGICA] = 'Analista Seguridad Lógica';
        roles[RoleUtilities.ROLE_MONITOR] = 'Monitor';
        return roles;
    };
}